import styled from 'styled-components';
import theme from "styles/style";

export const ButtonStyle = styled.span`
  display: contents;
    a {
      text-align: center;
      margin: 2rem;
      text-decoration: none;
    }
  
`

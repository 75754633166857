import LazyIframe from "components/common/iframe/lazyIframe";
import LayoutStep from "components/common/layout/layoutStep";
import Button from "components/ui/atoms/button/button";
import { titleTrainingDays } from "data/config";
import Link from "gatsby-link";
import React from "react";

import sky from "../../assets/images/sky.jpg";
import SEO from "../../components/SEO/SEO";

const Step6 = () => {
  return (
    <LayoutStep>
      <SEO
        title={`Training in workplace | ${titleTrainingDays}`}
        description={
          "Explanation about this module, that it is also common to the three programs (as the elementary training module and the occupational risks prevention module)"
        }
        canonical={"training-days/step-6/"}
      />
      <div className="container step">
        <p className={"h1"} id={"title"}>
          {titleTrainingDays} - Step 6{" "}
        </p>
        <h1 className={"slideLeft"}>TRAINING IN WORKPLACE</h1>
        <section className="videoCart">
          <main className={"slideLeft s05"}>
            <div className={"video--bloque--a"}></div>
            <div className={"embed-iframe"}>
              <div className={"sky"}></div>
              <img
                className={"videoCart__img"}
                alt={"Which options do the students have when finishing PCI?. Slide"}
                src={sky}
                width={"1280"}
                height={"720"}
              />
            </div>
          </main>
          <aside className={"slideLeft s1"}>
            <div className="stepNavigation">
              <Link to="/training-days/step-5-3/#top" className={"stepNavigation__button--back "}>
                {" "}
                <span className={"stepNavigation__button--back--right"}> </span> Back{" "}
              </Link>
              <Link to="/training-days/step-7/#top" className={"stepNavigation__button--next"}>
                {" "}
                <span className={"stepNavigation__button--next--left"}> </span> Next{" "}
              </Link>
            </div>
            <p>
              Explanation about this module, that it is also common to the three programs (as the elementary training
              module and the occupational risks prevention module)
            </p>
            <a className={"pdf"} href={"/downloads/training-in-workplace-PP.pdf"} rel="noreferrer" target={"_blank"}>
              Training in workplace
            </a>
          </aside>
        </section>
      </div>
    </LayoutStep>
  );
};

export default Step6;
